.basic-button,
.p-button-dark-blue,
.custom-primary-button,
.custom-dashboard-primary-button,
.custom-accent-button,
.custom-secondary-button,
.custom-tertiary-button {
  font-size: 14px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  text-align: center !important;
  color: white !important;
  box-shadow: none !important;
  margin: 0px 5px !important;
}

.p-button-warning {
  margin: 0px 5px !important;
  background-color: #ffb100 !important;
}

.p-button-warning:hover {
  background-color: #e7a201 !important;
}

.p-button-success {
  margin: 0px 5px !important;
  background-color: #59ad00 !important;
  border: 1px solid #59ad00 !important;
}

.p-button-success:hover {
  background-color: #59ad00 !important;
  border: 1px solid #59ad00 !important;
}

.p-button-dark-blue {
  margin: 0px 5px !important;
  font-size: 12px !important;
  background-color: #1F3BB3 !important;
  border: 1px solid #1F3BB3 !important;
}

.p-button-dark-blue:hover {
  font-size: 12px !important;
  background-color: #051666 !important;
  border: 1px solid #051666 !important;
}

.custom-primary-button {
  background-color: #0098f3 !important;
  margin: 0px 5px !important;
}

.custom-primary-button:hover {
  background-color: #007bc5 !important;
}

.custom-dashboard-primary-button {
  background-color: #060a45 !important;
  margin: 0px 5px !important;
}

.custom-dashboard-primary-button:hover {
  background-color: #060a45 !important;
}

.custom-accent-button {
  background-color: #26b810 !important;
}

.custom-accent-button:hover {
  background-color: #1d8d0c !important;
}

.custom-secondary-button {
  background-color: #94a3b0 !important;
}

.custom-secondary-button:hover {
  background-color: #5d6574 !important;
}

.custom-tertiary-button {
  color: black !important;
  border: 1px solid #94a3b0 !important;
  background-color: white !important;
}

.custom-tertiary-button:hover {
  color: #79818c !important;
  background-color: #edeef0 !important;
}
.p-button-red {
  margin: 0px 5px !important;
  font-size: 12px !important;
  background-color: #FF0000 !important;
  border: 1px solid #FF0000 !important;
}

.p-button-red:hover {
  font-size: 12px !important;
  background-color: #bb0505 !important;
  border: 1px solid #bb0505 !important;
}