.feature-not-implemented {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .feature-not-implemented h3 {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  