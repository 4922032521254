
.tooltip {
    position: relative;
    display: inline;
    cursor: pointer;
    opacity: 1;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #000000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 0.5em 1em;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: -110%;
    margin-left: -245px;
}
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 95%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
}
