/* .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    height: 60px;
    padding: 0 20px;
    width: 100%;
}

.topbar-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    
} */
.topbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px; /* set the height of the topbar */
    background-color: #f5f5f5; /* set the background color of the topbar */
  }
  .p-menubar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f5f5f5; /* set the background color of the topbar */

}
  
  