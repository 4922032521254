* {
    font-size: 14px;
}

.question-header {
    margin: 0px 15px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #d3caca;
    justify-content: center;
    font-weight: bold;
    background-color: white;
    color: black;
}



.question-type-list {
    flex-direction: column;
    justify-content: space-evenly;
    width: fit-content;
}

.question-type-list .question-type {
    background-color: #eee;
    border: 2px solid #fff;
    margin: 5px;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
}

.question-type-list .question-type:hover {
    background-color: #dbdbdb;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.question-type-list .question-type:active {
    border: 2px solid #0098f3;
}

.question-type-list .selected {
    background-color: #0098f3;
    color: #fff;
}

.question-type-list .selected:hover {
    background-color: #fff;
    color: #329ee2 #40bbea;
    border: 1px solid #afafaf;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.tab-view-label {
    color: #979898;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0.25rem;
    margin: 5px;
}

.tab-view .tab a {
    vertical-align: baseline;
}

.tab-view .p-tabview-selected a {
    background-color: #ffffff !important;
    border: 1px solid #0098f3 !important;
}

/* each question style */
.question-list {
    flex-direction: column;
}

.question-list .question {
    background-color: #eee;
    margin: 3px;
    padding: 8px 3px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    flex-wrap: nowrap;
}

.question-list .question-label {
    position: relative;
    margin: 0px 3px 0px 0px;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
}

/* to change the width of the Question Box */
.question-list .question-text {
    width: 12vw;
    overflow: hidden;
    white-space: nowrap;
}

.question-list .template-question {
    color: #0098f3;
}

.question-list .question:hover {
    background-color: #dbdbdb;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.question-list .question:hover .hidden-icon {
    display: block;
}



.question-list .question:hover .hover-icon {
    background-color: #dbdbdb c2;
}

.question-list .selected {
    background-color: #0098f3;
    color: #fff;
}



.question-list .selected .hover-icon {
    color: #585858;
    background-color: #0098f3 c2;
}

.question-list .selected:hover {
    background-color: #0098f3;
    color: #fff;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.question-list .selected:hover .hidden-icon {
    color: #fff;
}

.question-list .selected:hover .hidden-icon:hover {
    color: black;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.question-list .selected:hover .hover-icon {
    background-color: #0098f3 c2;
}

.question-list .hidden-icon {
    color: #afafaf;
    display: none;
    width: 20px;
    font-size: 19px;
}

.question-list .hidden-icon:hover {
    color: black;
    -webkit-transition: background-color 0.2s, -webkit-box-shadow 0.2s;
    transition: background-color 0.2s, -webkit-box-shadow 0.2s;
}

.question-list .hover-icon {
    position: absolute;
    font-size: 2em;
    background: #eee c2;
    color: #afafaf;
    width: 100%;
    text-align: center;
    transition: background-color 0s, -webkit-box-shadow 0s;
}

.question-list .move {
    cursor: move;
}

.option {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

}

.option .p-fileupload-choose {
    margin-right: 5px;
}

.option .selected-button {
    background-color: white !important;
    color: black;
}

.label {
    display: inline-block;
    margin-bottom: .25rem;
}

/* .p-dialog .p-dialog-content {
    background-color: #fff;
    color: #333;
    border: 1px solid #c8c8c8;
    padding: 1em;
    max-height: 55vh;
    min-height: 55vh;
}

.p-dialog .p-dialog-footer {
    border: 1px solid #c8c8c8;
    border-top: 0 none;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
    margin: 0;
    text-align: right;
} */

.survey-question-header,
.survey-question-header * {
    background-color: #eee;
    margin: 5px;
    padding: 4px 5px;
    font-size: 16px !important;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    flex-wrap: nowrap;
    font-weight: bold;
    text-align: center;
}

.survey-question-header>.question-text i.fa-exclamation-circle {
    color: #D32F2F;
    margin-right: 5px;
    background-color: transparent;
    /* Set the background color of the exclamation image to transparent */
}

.survey-question-page-count,
.survey-question-page-count * {

    padding: 4px 5px;
    font-size: 12px !important;
    flex-direction: row;
    align-items: center;
    /* cursor: pointer; */
    font-weight: bold;
    text-align: center;
}

.custom-calender {
    width: 100%;
}

.custom-calender .p-calendar {
    width: 100%;
    font-size: 14px !important;
}

.custom-calender .p-datepicker table td {
    padding: 0em 0.2em;
}

.custom-calender .p-datepicker-calendar {
    font-size: 14px !important;
    margin: 0px;
}

.image-close-duplicate {
    position: relative;
    /* display: block;
    */
    /* top: 10px;
    */
    /* right: 10px;
    */
    color: inherit !important;
    width: 200px;
    height: 100px;
    background-color: #eee;
    margin-right: 5px;
}

.image-upload {
    color: #eee;
    transition: color 0.5s;
}

.image-upload:hover {
    color: #dbdbdb;
}

.selected-choice {
    font-size: 12px;
}