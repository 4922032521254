.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  height: 50px;
  background-color: #ffffff;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
}


/* .topbar ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  } */

.topbar li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;

}

.topbarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  /* height: 60px; */
  /* padding: 0 20px; */
  width: 100vw;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  height: 40px;
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.p-dialog .p-dialog-content {
  background-color: #fff;
  color: #333;
  border: 1px solid #c8c8c8;
  padding: 1em;
  /* max-height: 55vh;
  min-height: 55vh; */
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem !important;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}