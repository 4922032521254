   /* .header {
    font-size: 13px;
    background-color: #fff;
    height: 54px;
    color: #000;
    padding: 10px 10px;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    position: sticky;

  } */
  .dropdown{
    width: fit-content;
  }
  .dropdown .btn-primary, 
.dropdown .btn-primary:focus {
  background-color: white;
  color: black;
  border: none;
}

.dropdown .btn-primary:hover, 
.dropdown .btn-primary:focus:hover {
  background-color: white;
  color: black;
  border: none;
}

.dropdown .dropdown-menu {
  width: 100%;
}

  