.footer{
    font-size: 13px;
    background-color: #000;
    height: 35px;
    color: #fff;
    padding: 10px 30px;
    bottom: 0px;
    position: fixed;
    width: 100%;
    text-align: right;
}