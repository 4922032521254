.parent{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.parent .spinner{
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50vw;
    top: 30vh;
}