.display-icon {
    min-height: 100px;
    width: 200px;
    object-fit: contain;
}
.display-icon:hover {
    opacity: 0.5;
    cursor: pointer;
}
.display-icon-max {
    max-width: 670px;
    object-fit: contain;
}
.dialog .p-dialog-content {
    max-width: 700px;
    max-height: 500px;
    overflow: hidden;
}
